import React from "react";
import Header from "../Component/Header";
import Banner from "../Component/Banner";
import ServiceProvide from "../Component/ServiceProvide";
import WhyChooseUs from "../Component/WhyChooseUs";
import TechnologyPart from "../Component/TechnologyPart";
import Testimonial from "../Component/Testimonial";
import Clientdetail from "../Component/Clientdetail";
import Footer from "../Component/Footer";



function Home() {
 
  return (
    <>
      <div>
        <div className="sticky top-0 z-50">
          <Header />
        </div>
        <div>
          <Banner />
        </div>
        <div>
          <ServiceProvide />
        </div>
        <div>
          <WhyChooseUs />
        </div>
        <div className="px-5">
          <TechnologyPart />
        </div>
        <div>
          <Testimonial />
        </div>
        <div>
          <Clientdetail />
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Home;

{
  /* <div className="banner-container">
  <div className="center_content flex flex-col gap-3 text-center">
    <div className="text-gray-400 text-3xl">
      <h1>Coding Success Stories, Every Project, Every Time.</h1>
    </div>
    <div className="text-white flex flex-col gap-3 text-7xl">
      <h1 className="font-bold">Digital Agency Business</h1>
      <span className="text_color text-heading">
        <Typewriter
          dataToRotate={[
            [{ type: "word", text: " SEO" }],
            [{ type: "word", text: " Digital Marketing" }],
            [{ type: "word", text: " Web Development" }],
            [{ type: "word", text: " Game Development" }],
          ]}
          loop={true}
        />
      </span>
    </div>
    <div className="text-gray-400 text-3xl">
      <h1>Your Digital Journey Starts Here.</h1>
    </div>
    <div className="text-gray-400 text-2xl">
      <h1>Dream, Develop, Deliver</h1>
    </div>
    <div className="flex gap-20 justify-center mt-10">
      <button
        onClick={handleAboutUsPage}
        className="text-base font-semibold leading-6 text-black px-4 py-2 bg-white custom-btn btn-16"
      >
        Know More <span aria-hidden="true">&rarr;</span>
      </button>
      <button
        onClick={handleInstitutePage}
        className="text-base font-semibold leading-6 text-black px-4 py-2 bg-white custom-btn btn-16"
      >
        Learn More <span aria-hidden="true">&rarr;</span>
      </button>
    </div>
  </div>
</div>; */
}
