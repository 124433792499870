import React, { useEffect } from "react";
import Header from "../Component/Header";
import Footer from "../Component/Footer";

import banner from "../assets/Images/Banner/about-us.avif";
import big_banner from "../assets/Images/Banner/big_banner.avif";
import banners from "../assets/Images/Banner/binary_banner.png";

function About() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <div>
        <Header />
      </div>

      <div className="text-center text-3xl relative">
        <div className="banner-overlay"></div>
        <img className="h-56 md:h-52 w-screen" src={banners} alt="" />
        <div className="absolute inset-0 py-16 md:py-10 lg:py-10 xl:py-14">
          <p className="tracking-widest text-xl md:text-4xl py-5 text-white">
            ABOUT US
          </p>
        </div>
      </div>
      <div className="container mx-auto px-5">
        <div className="flex flex-col-reverse md:flex-row items-center justify-between py-10">
          <div className="w-full md:w-2/4 md:pr-10">
            <h1 className="text-4xl md:text-3xl lg:text-5xl font-semibold py-5">
              We’re changing the way people connect.
            </h1>
            <p className="text-xl md:text-xl text_color_p">
              At Octant Infotech Pvt Ltd, we accept that innovation can
              revolutionize how we connect. We're not simply discussing video
              calls and social media, or faster Wi-Fi or wider bandwidth, though
              those are important too. But we're looking at making further, more
              significant connections that rise above actual limits.
            </p>
            <br />
            <p className="text-xl md:text-xl text_color_p">
              In a world overwhelmed by digital interactions, we've lost focus
              on the human touch. We look at vast feeds, chasing likes and
              comments, But certified connection feels progressively precise.
              Octant Infotech is here to bridge the gap.
            </p>
          </div>
          <div>
            <img src={banner} alt="" />
          </div>
        </div>

        <div>
          <div>
            <h1 className="text-5xl md:text-6xl font-semibold md:py-5">
              Our values
            </h1>
            <p className="text-xl mt-2 md:text-xl text_color_p">
              At Octant Infotech, we make connections rather than just code.
              However, these connections aren't manufactured in silicon or
              fiber, they're based on the bedrock of unfaltering values that
              guide all that we do. These values aren't simply words on a site,
              they're the beating heart of our company, the imperceptible
              threads woven into each task, each collaboration, and each line of
              code.
            </p>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 py-5 md:py-16">
              <div>
                <h1 class="text-xl font-semibold">Be world-class</h1>
                <p class="text-xl text_color_p py-2">
                  We strive to deliver solutions that not only meet expectations
                  but also exceed them, we strive for excellence by pushing the
                  boundaries of technology and creativity.
                </p>
                <br />
                <p class="text-xl text_color_p">
                  We benchmark ourselves against the best, continually learning
                  and developing to remain on the curve. In a universe of brief
                  patterns, we mean to make timeless effects.
                </p>
              </div>
              <div>
                <h1 class="text-xl font-semibold">Share everything you know</h1>
                <p class="text-xl text_color_p py-2">
                  Knowledge is power, but only just when you share. We trust in
                  open communication and transparency. We actively share our
                  knowledge, not only with our team but also with the wider
                  community.
                </p>
                <br />
                <p class="text-xl text_color_p">
                  We add to open-source projects, mentor budding developers, and
                  host workshops to democratize knowledge and empower others.
                </p>
              </div>
              <div>
                <h1 class="text-xl font-semibold">Always learning</h1>
                <p class="text-xl text_color_p py-2">
                  Technology is an infinite journey, and so is our learning. We
                  embrace interest, continually looking for new information and
                  skills.
                </p>
                <br />
                <p class="text-xl text_color_p">
                  We embrace a development mentality, continuously searching for
                  better approaches to learn, adapt, and get to the next level.
                  We encourage trial and error, curiosity, and a readiness to
                  step outside our comfort zone.
                </p>
              </div>
              <div>
                <h1 class="text-xl font-semibold">Be supportive</h1>
                <div class="paracontents py-2">
                  <p class="text-xl text_color_p">
                    We trust in the power of collaboration. At Octant, we foster
                    a culture of mutual support because nobody succeeds on their
                    own.
                  </p>
                  <br />
                  <p class="text-xl text_color_p">
                    We share information readily, praise each other's victories,
                    and pick each other up when challenges arise.
                  </p>
                </div>
              </div>
              <div>
                <h1 class="text-xl font-semibold">Take responsibility</h1>
                <div class="py-2 paracontent">
                  <p class="text-xl text_color_p">
                    We own our activities, our choices, and their results. We
                    trust in responsibility and transparency, taking
                    responsibility for work and holding ourselves to the best
                    expectations.
                  </p>
                  <br />
                  <p class="text-xl text_color_p">
                    We understand that trust is earned, and we strive to be
                    reliable partners, both inside and with our clients.
                  </p>
                </div>
              </div>
              <div>
                <h1 class="text-xl font-semibold">Enjoy downtime</h1>
                <div class="py-2">
                  <p class="text-xl text_color_p">
                    We trust in the power of a very much refreshed mind. While
                    we're passionate about our work, we also grasp the
                    importance of taking breaks.
                  </p>
                  <br />
                  <p class="text-xl text_color_p">
                    We encourage our team to disconnect, re-energize, and seek
                    after their interests beyond work. A happy, adjusted group
                    is a productive team, and we ensure everybody has the space
                    to flourish both professionally and personally.
                  </p>
                </div>
              </div>
            </div>

            <div className="py-10">
              <img src={big_banner} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
}

export default About;
