import React from "react";

import html from "../assets/Images/techImg/Html.png";
import css from "../assets/Images/techImg/css.png";
import javascript from "../assets/Images/techImg/javascript.png";
import bootstrap from "../assets/Images/techImg/bootstrep.png";
import tailwind from "../assets/Images/techImg/tailwind.png";
import react from "../assets/Images/techImg/React.png";
import android from "../assets/Images/techImg/android.png";
import angular from "../assets/Images/techImg/angular.png";
import figma from "../assets/Images/techImg/figma.png";
import flutter from "../assets/Images/techImg/flutter.png";
import laravel from "../assets/Images/techImg/laravel.png";
import mongoDB from "../assets/Images/techImg/mongoDB.png";
import nodeJs from "../assets/Images/techImg/nodejs.png";
import php from "../assets/Images/techImg/php.png";
import typescript from "../assets/Images/techImg/typescript.png";
import vue from "../assets/Images/techImg/vuejs.png";

function TechnologyPart() {
  const techImgLine = [
    html,
    css,
    javascript,
    bootstrap,
    tailwind,
    react,
    android,
    angular,
    figma,
  ];
  const techImgLines = [
    flutter,
    laravel,
    mongoDB,
    nodeJs,
    php,
    typescript,
    vue,
    flutter,
  ];
  return (
    <>
      <div className="py-5 md:py-10 lg:py-10 xl:py-10">
        <div className="text-head text-2xl md:text-3xl lg:text-5xl">
          <h1>Technologies We Work With</h1>
        </div>
        <div className="container texhnologys mx-auto overflow-hidden py-5 md:py-10 lg:py-10 xl:py-20">
          <div className="marquee py-2">
            {techImgLine.map((img, index) => (
              <div className="marquee-items " key={index}>
                <img
                  className="h-20 tech-element md:h-28 lg:h-32 xl:h-40"
                  src={img}
                  alt=""
                />
              </div>
            ))}
          </div>
          <div className="marquees py-2">
            {techImgLines.map((img, index) => (
              <div className="marquee-items" key={index}>
                <img
                  className="h-20  tech-element md:h-28 lg:h-32 xl:h-40"
                  src={img}
                  alt=""
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default TechnologyPart;
