import React, { useEffect } from "react";
import VanillaTilt from "vanilla-tilt";

import app from "../assets/Images/Icon/app-design.png";
import game from "../assets/Images/Icon/game-console.png";
import uiux from "../assets/Images/Icon/uiux.png";
import web from "../assets/Images/Icon/web.png";
import { useNavigate } from "react-router-dom";

function ServiceProvide() {
  const navigate = useNavigate();

  useEffect(() => {
    VanillaTilt.init(document.querySelectorAll(".your-element"), {
      max: 14,
      speed: 400,
    });
  }, []);

  const handleServicePage = () => {
    navigate("./service");
  };
  return (
    <>
      <div className="py-5 md:py-10 lg:py-10 xl:py-10">
        <div className="text-head text-2xl md:text-3xl lg:text-5xl">
          <h1>We are Services Provide</h1>
        </div>

        <div className="mx-8">
          <div class="mx-auto container py-8 md:py-10 lg:py-10 xl:py-20">
            <div class="max-w-md sm:max-w-full mx-auto grid grid-cols-1 lg:mx-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 sm:gap-x-6 md:gap-x-10 xl:gap-x-16">
              <div class="your-element py-10 my-3 px-5">
                <div class="text-center flex flex-col gap-5">
                  <div class="flex justify-center rounded-md lg:aspect-none group-hover:opacity-75 ">
                    <div class="image_service">
                      <img class="xl:h-32" src={app} />
                    </div>
                  </div>
                  <div class="text-xl font-semibold">
                    <h1>APP DEVELOPMENT</h1>
                  </div>
                </div>
              </div>

              <div class="your-element py-10 my-3 px-5">
                <div class="text-center flex flex-col gap-5">
                  <div class="flex justify-center rounded-md lg:aspect-none group-hover:opacity-75 ">
                    <div class="image_service">
                      <img class="xl:h-32" src={web} />
                    </div>
                  </div>
                  <div class="text-xl font-semibold">
                    <h1>WEB DEVELOPMENT</h1>
                  </div>
                </div>
              </div>

              <div class="your-element py-10 my-3 px-5">
                <div class="text-center flex flex-col gap-5">
                  <div class="flex justify-center rounded-md lg:aspect-none group-hover:opacity-75 ">
                    <div class="image_service">
                      <img class="xl:h-32" src={game} />
                    </div>
                  </div>
                  <div class="text-xl font-semibold">
                    <h1>GAME DEVELOPMENT</h1>
                  </div>
                </div>
              </div>

              <div class="your-element py-10 my-3 px-5">
                <div class="text-center flex flex-col gap-5">
                  <div class="flex justify-center rounded-md lg:aspect-none group-hover:opacity-75 ">
                    <div class="image_service">
                      <img class="xl:h-32" src={uiux} />
                    </div>
                  </div>
                  <div class="text-xl font-semibold">
                    <h1>UI/UX DESIGN</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center py-5">
            <button
              onClick={handleServicePage}
              className="text-base font-semibold leading-6 text-white px-4 bg-black custom-btn btn-5"
            >
              Know Many More Services <span aria-hidden="true">&rarr;</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceProvide;
