import Home from "./pages/Home";
import About from "./pages/About";
import Service from "./pages/Service";
// import Career from "./pages/Career";
import Institute from "./pages/Institute";
import Contact from "./pages/Contact";
import "./assets/css/Global.css";
import "animate.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <div>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/service" element={<Service />} />
            {/* <Route path="/career" element={<Career />} /> */}
            <Route path="/institute" element={<Institute />} />
            <Route path="/contact-us" element={<Contact />} />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
