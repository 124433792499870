import React from "react";
import Typewriter from "react-typewriter-animate";
import "react-typewriter-animate/dist/Typewriter.css";
import banner_img from "../assets/Images/Banner/banner_small_size.jpg";
import { useNavigate } from "react-router-dom";

function Banner() {
  const navigate = useNavigate();
  const handleAboutUsPage = () => {
    navigate("./about-us");
  };

  const handleInstitutePage = () => {
    navigate("./institute");
  };
  return (
    <>
      <div>
        <div className="banner_img relative">
          <img
            className="h-96 w-screen md:w-screen md:h-screen"
            src={banner_img}
            alt=""
          />
          <div className="center_content flex flex-col gap-4 lg:gap-7 text-center">
            <div className="text-gray-300 text-sm md:text-2xl lg:text-2xl">
              <h1>Coding Success Stories, Every Project, Every Time.</h1>
            </div>
            <div className="text-white flex flex-col gap-2 sm:gap-2 md:gap-3 xl:gap-7 text-2xl md:text-4xl lg:text-4xl xl:text-6xl">
              <h1 className="font-bold">Digital Agency Business</h1>
              <span className="text_color text-heading">
                <Typewriter
                  dataToRotate={[
                    [{ type: "word", text: " SEO" }],
                    [{ type: "word", text: " Digital Marketing" }],
                    [{ type: "word", text: " Web Development" }],
                    [{ type: "word", text: " Game Development" }],
                  ]}
                  loop={true}
                />
              </span>
            </div>
            <div className="text-gray-300 text-base md:text-2xl lg:text-2xl">
              <h1>Your Digital Journey Starts Here.</h1>
            </div>
            <div className="text-gray-300 text-sm md:text-xl lg:text- xl">
              <h1>Dream, Develop, Deliver</h1>
            </div>
            <div className="flex gap-10 md:gap-20 justify-center mt-10">
              <button
                onClick={handleAboutUsPage}
                className="text-sm md:text-base font-semibold leading-6 text-black px-4 py-2 bg-white custom-btn btn-16"
              >
                Know More <span aria-hidden="true">&rarr;</span>
              </button>
              <button
                onClick={handleInstitutePage}
                className="text-sm md:text-base font-semibold leading-6 text-black px-4 py-2 bg-white custom-btn btn-16"
              >
                Learn More <span aria-hidden="true">&rarr;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
