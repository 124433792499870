import React, { useEffect } from "react";
import VanillaTilt from "vanilla-tilt";

import android from "../assets/Images/Icon/strategy.png";
import ontime from "../assets/Images/Icon/ontime.png";
import custom from "../assets/Images/Icon/custom.png";
import pricing from "../assets/Images/Icon/competitive.png";
import team from "../assets/Images/Icon/team.png";

function WhyChooseUs() {
  useEffect(() => {
    VanillaTilt.init(document.querySelectorAll(".your-element"), {
      max: 14,
      speed: 400,
    });
  }, []);
  return (
    <>
      <div className="py-1 md:py-10 lg:py-10 xl:py-10">
        <div className="text-head text-2xl md:text-3xl lg:text-5xl">
          <h1>Why Choose Us</h1>
        </div>
        <div>
          <div className="bg-white mx-5 py-5 md:py-10 lg:py-10 xl:py-14">
            <div className="mx-auto flex justify-center container">
              <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-20">
                <div className="your-element hover_effect">
                  <div className="bg_banner">
                    <div className="line_boxs py-5 px-5 sm:py-10 md:py-8 md:px-6">
                      <div className="text-center flex flex-col gap-5">
                        <div className="flex items-center justify-evenly rounded-md lg:aspect-none group-hover:opacity-75">
                          <img className="h-20 md:h-20" src={android} />
                          <div className="text-2xl font-semibold">
                            <h1>
                              Strategic <br /> Approch
                            </h1>
                          </div>
                        </div>
                        <div className="text-xl text_color_p font-semibold">
                          <p>
                            We find opportunities to understand your industry,
                            your competition, and your long-term vision, then,
                            at that point, influence our expertise to recommend
                            solutions that drive you forward.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="your-element hover_effect">
                  <div className="bg_banner">
                    <div className="line_boxs py-5 px-5 sm:py-10 md:py-8 md:px-6">
                      <div className="text-center flex flex-col gap-5">
                        <div className="flex items-center justify-evenly rounded-md lg:aspect-none group-hover:opacity-75">
                          <img className="h-20 md:h-20" src={ontime} />
                          <div className="text-2xl font-semibold">
                            <h1>
                              On-Time <br /> Delivery
                            </h1>
                          </div>
                        </div>
                        <div className="text-xl text_color_p font-semibold">
                          <p>
                            Our demonstrated project management methodologies
                            and transparent communication ensure that your
                            projects are delivered on time and within budget,
                            limiting interruptions and minimizing your return on
                            capital invested.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="your-element hover_effect">
                  <div className="bg_banner">
                    <div className="line_boxs py-5 px-5 sm:py-10 md:py-8 md:px-6">
                      <div className="text-center flex flex-col gap-5">
                        <div className="flex items-center justify-evenly rounded-md lg:aspect-none group-hover:opacity-75">
                          <img className="h-20 md:h-20" src={custom} />
                          <div className="text-2xl font-semibold">
                            <h1>
                              Customized <br />
                              Solution
                            </h1>
                          </div>
                        </div>
                        <div className="text-xl text_color_p font-semibold">
                          <p>
                            Our team of experts works closely with you to
                            understand your particular requirements. We don't
                            offer cookie-cutter solutions, we create
                            custom-tailored arrangements that perfectly
                            coordinate with your current infrastructure and work
                            process.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="your-element hover_effect">
                  <div className="bg_banner">
                    <div className="line_boxs py-5 px-5 sm:py-10 md:py-8 md:px-6">
                      <div className="text-center flex flex-col gap-5">
                        <div className="flex items-center justify-evenly rounded-md lg:aspect-none group-hover:opacity-75">
                          <img className="h-20 md:h-20" src={pricing} />
                          <div className="text-2xl font-semibold">
                            <h1>
                              Competitive <br />
                              Pricing
                            </h1>
                          </div>
                        </div>
                        <div className="text-xl text_color_p font-semibold">
                          <p>
                            We offer competitive rates without compromising on
                            quality. We have confidence in building a long-term
                            partnership, and our pricing reflects our commitment
                            to offering some benefit and exceeding your
                            assumptions.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="your-element hover_effect">
                  <div className="bg_banner">
                    <div className="line_boxs py-5 px-5 sm:py-10 md:py-8 md:px-6">
                      <div className="text-center flex flex-col gap-5">
                        <div className="flex items-center justify-evenly rounded-md lg:aspect-none group-hover:opacity-75">
                          <img className="h-20 md:h-20" src={team} />
                          <div className="text-2xl font-semibold">
                            <h1>
                              Dedicated <br />
                              Team
                            </h1>
                          </div>
                        </div>
                        <div className="text-xl text_color_p font-semibold">
                          <p>
                            We relegate a committed group of specialists to your
                            undertaking, ensuring you have reliable resources
                            and progressing support all through the entire
                            commitment. We're passionate about your prosperity,
                            and we're here to direct you constantly.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhyChooseUs;
