import React, { useEffect } from "react";
import Header from "../Component/Header";
import Footer from "../Component/Footer";

import banners from "../assets/Images/Banner/binary_banner.png";

import web from "../assets/Images/Icon/web.png";
import app from "../assets/Images/Icon/app-design.png";
import game from "../assets/Images/Icon/game-console.png";
import uiux from "../assets/Images/Icon/uiux.png";
import add from "../assets/Images/Icon/layout.png";
import seo from "../assets/Images/Icon/seo.png";
import server from "../assets/Images/Icon/server.png";
import soft from "../assets/Images/Icon/coding.png";
import graphics from "../assets/Images/Icon/editorial.png";

function Service() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <div>
        <Header />
      </div>
      <div>
        <div className="text-center text-3xl relative">
          <div className="banner-overlay"></div>
          <img className="h-56 md:h-52 w-screen" src={banners} alt="" />
          <div className="absolute inset-0 py-16 md:py-10 lg:py-10 xl:py-14">
            <p className="tracking-widest text-xl md:text-4xl py-5 text-white">
              OUR SERVICES
            </p>
          </div>
        </div>
      </div>
      <div className="mx-auto flex justify-center container py-8 md:py-15 lg:py-15 xl:py-15">
        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
          <div className="your-element hover_effect">
            <div className="bg_banner">
              <div className="line_boxes">
                <div className="text-center flex flex-col gap-5">
                  <div className="flex items-center justify-evenly rounded-md lg:aspect-none group-hover:opacity-75">
                    <img className="h-20 md:h-20" src={web} />
                    <div className="text-2xl font-semibold">
                      <h1>
                        Web <br /> Development
                      </h1>
                    </div>
                  </div>
                  <div className="text-xl text_color_p font-semibold">
                    <p>Build stunning, user-friendly websites that convert.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="your-element hover_effect">
            <div className="bg_banner">
              <div className="line_boxes">
                <div className="text-center flex flex-col gap-5">
                  <div className="flex items-center justify-evenly rounded-md lg:aspect-none group-hover:opacity-75">
                    <img className="h-20 md:h-20" src={app} />
                    <div className="text-2xl font-semibold">
                      <h1>
                        App <br /> Development
                      </h1>
                    </div>
                  </div>
                  <div className="text-xl text_color_p font-semibold">
                    <p>
                      Make instinctive mobile applications that engage your
                      audience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="your-element hover_effect">
            <div className="bg_banner">
              <div className="line_boxes">
                <div className="text-center flex flex-col gap-5">
                  <div className="flex items-center justify-evenly rounded-md lg:aspect-none group-hover:opacity-75">
                    <img className="h-20 md:h-20" src={game} />
                    <div className="text-2xl font-semibold">
                      <h1>
                        Game <br />
                        Development
                      </h1>
                    </div>
                  </div>
                  <div className="text-xl text_color_p font-semibold">
                    <p>Bring your fantasy game with immersive experiences.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="your-element hover_effect">
            <div className="bg_banner">
              <div className="line_boxes">
                <div className="text-center flex flex-col gap-5">
                  <div className="flex items-center justify-evenly rounded-md lg:aspect-none group-hover:opacity-75">
                    <img className="h-20 md:h-20" src={uiux} />
                    <div className="text-2xl font-semibold">
                      <h1>
                        UI/UX <br />
                        Designing
                      </h1>
                    </div>
                  </div>
                  <div className="text-xl text_color_p font-semibold">
                    <p>
                      Design interfaces that are both eye-catching and
                      functional.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="your-element hover_effect">
            <div className="bg_banner">
              <div className="line_boxes">
                <div className="text-center flex flex-col gap-5">
                  <div className="flex items-center justify-evenly rounded-md lg:aspect-none group-hover:opacity-75">
                    <img className="h-20 md:h-20" src={seo} />
                    <div className="text-2xl font-semibold">
                      <h1>
                        SEO & Digital
                        <br />
                        Marketing
                      </h1>
                    </div>
                  </div>
                  <div className="text-xl text_color_p font-semibold">
                    <p>
                      Dominate web search tools and reach your target audience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="your-element hover_effect">
            <div className="bg_banner">
              <div className="line_boxes">
                <div className="text-center flex flex-col gap-5">
                  <div className="flex items-center justify-evenly rounded-md lg:aspect-none group-hover:opacity-75">
                    <img className="h-20 md:h-20" src={add} />
                    <div className="text-2xl font-semibold">
                      <h1>
                        Social Media
                        <br />
                        Design
                      </h1>
                    </div>
                  </div>
                  <div className="text-xl text_color_p font-semibold">
                    <p>
                      Make eye-catching visuals that fuel your social media
                      presence.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="your-element hover_effect">
            <div className="bg_banner">
              <div className="line_boxes">
                <div className="text-center flex flex-col gap-5">
                  <div className="flex items-center justify-evenly rounded-md lg:aspect-none group-hover:opacity-75">
                    <img className="h-20 md:h-20" src={server} />
                    <div className="text-2xl font-semibold">
                      <h1>
                        Web
                        <br />
                        Hoisting
                      </h1>
                    </div>
                  </div>
                  <div className="text-xl text_color_p font-semibold">
                    <p>
                      Secure, reliable hosting solutions for your website or
                      app.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="your-element hover_effect">
            <div className="bg_banner">
              <div className="line_boxes">
                <div className="text-center flex flex-col gap-5">
                  <div className="flex items-center justify-evenly rounded-md lg:aspect-none group-hover:opacity-75">
                    <img className="h-20 md:h-20" src={soft} />
                    <div className="text-2xl font-semibold">
                      <h1>
                        Software
                        <br />
                        Development
                      </h1>
                    </div>
                  </div>
                  <div className="text-xl text_color_p font-semibold">
                    <p>
                      Customized programming solution to meet your special
                      requirements.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="your-element hover_effect">
            <div className="bg_banner">
              <div className="line_boxes">
                <div className="text-center flex flex-col gap-5">
                  <div className="flex items-center justify-evenly rounded-md lg:aspect-none group-hover:opacity-75">
                    <img className="h-20 md:h-20" src={graphics} />
                    <div className="text-2xl font-semibold">
                      <h1>
                        Graphics
                        <br />
                        Designing
                      </h1>
                    </div>
                  </div>
                  <div className="text-xl text_color_p font-semibold">
                    <p>
                      Captivate your crowd with dazzling visual storytelling.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-5 py-8 md:py-20">
        <div className="lets_start  relative text-center">
          <div className="absolute content_inner">
            <h2 className="text-base md:text-2xl tracking-widest text-white">
              ARE YOU READY FOR
            </h2>
            <h1 className="text-2xl md:text-6xl py-2 text-white font-semibold">
              Lets Get Started <br /> Your Project
            </h1>
            <div className="pt-10">
              <a href="#" className="btns">
                Start Now <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
}

export default Service;
