import React from "react";
import { IoMdCall, IoIosMail } from "react-icons/io";
import logo_horizantal from "../assets/Images/Logo/Horizantal_Logo.svg";

import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { NavLink, useLocation } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Footer() {
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <>
      <div className="footer_img bg-black py-10">
        <div className="flex items-center justify-evenly lg:mx-8">
          <div className="container mx-auto">
            <div className="px-5 md:px-5 lg:px-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4">
              <div className="firse_side">
                <img
                  className="h-20 cursor-pointer"
                  src={logo_horizantal}
                  alt=""
                />
                <p className="text-white pe-16 pt-3">
                  At Octant Infotech, we don't just build sites, we build
                  flourishing digital environments.
                </p>
                <br />
                <p className="text-white pe-16">
                  Allow us to be your guide as you explore the always-advancing
                  digital landscape and achieve your online goals.
                </p>
              </div>
              <div className="third_side lg:mt-0 mt-12 md:mt-0">
                <h1 className="text-heading font-bold text-3xl">Services</h1>
                <div className="flex flex-col mt-5">
                  <ul class="font-medium  flex flex-col md:gap-3">
                    <li>
                      <a
                        href="#"
                        class="block py-2 text-white font-bold rounded md:p-0 dark:text-white"
                        aria-current="page"
                      >
                        Web Development
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        class="block py-2 text-white rounded font-bold md:p-0 dark:text-white"
                      >
                        Seo & Digital Marketing
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        class="block py-2 text-white rounded font-bold md:p-0 dark:text-white"
                      >
                        App Development
                      </a>
                    </li>

                    <li>
                      <a
                        href="#"
                        class="block py-2 text-white rounded font-bold md:p-0 dark:text-white"
                      >
                        Game Development
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        class="block py-2 text-white rounded font-bold md:p-0 dark:text-white"
                      >
                        UI/UX Design
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="second_side">
                <h1 className="text-heading font-bold text-3xl mt-12 md:mt-10 lg:mt-12 xl:mt-0">
                  Links
                </h1>
                <div className="flex flex-col mt-5">
                  <ul class="font-medium text-white flex flex-col gap-3">
                    <NavLink
                      to="/"
                      className={classNames(
                        "text-base font-semibold leading-6 text-white",
                        isActive("/") && "text_color"
                      )}
                    >
                      Home
                    </NavLink>

                    <NavLink
                      to="/about-us"
                      className={classNames(
                        "text-base font-semibold leading-6 text-white",
                        isActive("/about-us") && "text_color"
                      )}
                    >
                      About Us
                    </NavLink>

                    <NavLink
                      to="/service"
                      className={classNames(
                        "text-base font-semibold leading-6 text-white",
                        isActive("/service") && "text_color"
                      )}
                    >
                      Services
                    </NavLink>

                    <NavLink
                      to="/contact-us"
                      className={classNames(
                        "text-base font-semibold leading-6 text-white",
                        isActive("/contact-us") && "text_color"
                      )}
                    >
                      Contact Us
                    </NavLink>

                    <NavLink
                      to="/institute"
                      className={classNames(
                        "text-base font-semibold leading-6 text-white",
                        isActive("/institute") && "text_color"
                      )}
                    >
                      Institute
                    </NavLink>
                  </ul>
                </div>
              </div>
              <div className="fourth_side">
                <h1 className="text-heading font-bold text-3xl mt-0 md:mt-10 lg:mt-0 footer_margin xl:mt-0">Contact Us</h1>
                <div className="flex flex-col gap-3 mt-5">
                  <div className="flex items-center gap-2">
                    <h1 className="text-white text-3xl cursor-pointer">
                      <IoMdCall />
                    </h1>
                    <h1
                      className="text-white text-xl cursor-pointer"
                      onClick={() =>
                        (window.location.href = "tel:+916352045341")
                      }
                    >
                      +91 6352045341
                    </h1>
                  </div>
                  <div className="flex items-center gap-2">
                    <h1 className="text-white text-3xl cursor-pointer">
                      <IoIosMail />
                    </h1>
                    <h1
                      className="text-white text-xl cursor-pointer"
                      onClick={() =>
                        (window.location.href =
                          "mailto:octantinfotech12@gmail.com")
                      }
                    >
                      octantinfotech12@gmail.com
                    </h1>
                  </div>
                  <h1 className="text-white text-xl">
                    814, Sliver Trade Center, <br /> Mota Varachha, Surat,
                    India, 395010.
                  </h1>
                </div>
                <div className="social_icon flex items-center gap-8 mt-5">
                  <div className="social_icons text-white text-2xl cursor-pointer">
                    <FaFacebookF />
                  </div>
                  <div className="social_icons text-white text-2xl cursor-pointer">
                    <a
                      href="https://www.instagram.com/octant_infotech_?igsh=Y3M5bzRqeTYzZzdr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram />
                    </a>
                  </div>
                  <div className="social_icons text-white text-2xl cursor-pointer">
                    <FaLinkedinIn />
                  </div>
                  <div
                    className="social_icons text-white text-2xl cursor-pointer"
                    onClick={() => (window.location.href = "tel:+916352045341")}
                  >
                    <IoMdCall />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
