import React, { useEffect } from "react";
import Header from "../Component/Header";
import Footer from "../Component/Footer";

import banners from "../assets/Images/Banner/binary_banner.png";
import certi from "../assets/Images/octant-cource/certificate_badge.png";

import java from "../assets/Images/octant-cource/java.png";
import app from "../assets/Images/octant-cource/Android_Logo_2014_-_2019 1.png";
import apple from "../assets/Images/octant-cource/apple-logo.svg";
import react from "../assets/Images/octant-cource/React-icon.svg.png";
import node from "../assets/Images/octant-cource/node-js-icon.png";
import angular from "../assets/Images/octant-cource/angular.png";
import php from "../assets/Images/octant-cource/PHP-logo.svg.png";
import wordpress from "../assets/Images/octant-cource/WordPress_blue_logo.svg.png";
import clang from "../assets/Images/octant-cource/695px-C_Programming_Language.svg.png";
import cplus from "../assets/Images/octant-cource/ISO_C++_Logo.svg.png";
import flutter from "../assets/Images/octant-cource/Flutter_logo.svg.png";
import html from "../assets/Images/octant-cource/html5-icon-13.png";
import css from "../assets/Images/octant-cource/CSS3_logo.svg.png";
import javascript from "../assets/Images/octant-cource/javascript-3.png";
import boot from "../assets/Images/octant-cource/Bootstrap_logo.svg.png";
import tailwind from "../assets/Images/octant-cource/tailwind.png";
import photoshop from "../assets/Images/octant-cource/Adobe_Photoshop_CC_icon.svg.png";
import illustrator from "../assets/Images/octant-cource/Adobe_Illustrator_CC_icon.svg.png";
import coral from "../assets/Images/octant-cource/coreldraw-icon.webp";
import figma from "../assets/Images/octant-cource/figma.png";

function Institute() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <div>
        <Header />
      </div>

      <div className="text-center text-3xl relative">
        <div className="banner-overlay"></div>
        <img className="h-56 md:h-52 w-screen" src={banners} alt="" />
        <div className="absolute inset-0 py-16 md:py-10 lg:py-10 xl:py-14">
          <p className="tracking-widest text-xl md:text-4xl py-5 text-white">
            INSTITUTE
          </p>
        </div>
      </div>

      <div className="container mx-auto px-5">
        <div>
          <div className="py-5 text-center">
            <h1 className="text-3xl md:text-5xl text-heading tracking-widest pt-5">
              Student Courses
            </h1>
          </div>
        </div>
        <div className="text-center">
          <h1 className="text-xl md:text-3xl">STD 10th & 12th</h1>
          <p className="py-3 text-base">
            Put the stress of exams behind you. The main thing is your passion,
            your curiosity, and your drive to explore. <br /> Instead of just
            focusing on marks, we believe in cultivating these distinctive
            qualities.
          </p>
          <div className="flex flex-col justify-center items-center py-10">
            <div className="certificate">
              <img className="h-40" src={certi} alt="" />
            </div>
            <p className="py-2">
              Your Future Starts Now: Build your Dreams with Octant Infotech
              after 10 & 12
            </p>
          </div>
        </div>

        <div>
          <div className="text-xl sm:text-3xl md:text-4xl text-center text-head py-2 sm:py-5">
            <h1>Programming Languges</h1>
          </div>
          <div className="grid grid-cols-2 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8 py-10">
            <div className="text-center flex flex-col justify-center items-center">
              <div className="cources">
                <img className="h-24 md:h-36" src={java} alt="" />
              </div>
              <h1 className="text-base md:text-2xl py-3">java</h1>
            </div>
            <div className="text-center flex flex-col justify-center items-center">
              <div className="cources">
                <img className="h-24 md:h-36" src={app} alt="" />
              </div>
              <h1 className="text-base md:text-2xl py-3">Android</h1>
            </div>
            <div className="text-center flex flex-col justify-center items-center">
              <div className="cources">
                <img className="h-24 md:h-36" src={apple} alt="" />
              </div>
              <h1 className="text-base md:text-2xl py-3">IOS</h1>
            </div>
            <div className="text-center flex flex-col justify-center items-center">
              <div className="cources">
                <img className="h-24 md:h-36" src={react} alt="" />
              </div>
              <h1 className="text-base md:text-2xl py-3">ReactJs</h1>
            </div>
            <div className="text-center flex flex-col justify-center items-center">
              <div className="cources">
                <img className="h-24 md:h-36" src={node} alt="" />
              </div>
              <h1 className="text-base md:text-2xl py-3">NodeJs</h1>
            </div>
            <div className="text-center flex flex-col justify-center items-center">
              <div className="cources">
                <img className="h-24 md:h-36" src={angular} alt="" />
              </div>
              <h1 className="text-base md:text-2xl py-3">AngularJs</h1>
            </div>
            <div className="text-center flex flex-col justify-center items-center">
              <div className="cources">
                <img className="h-24 md:h-36" src={php} alt="" />
              </div>
              <h1 className="text-base md:text-2xl py-3">PHP</h1>
            </div>
            <div className="text-center flex flex-col justify-center items-center">
              <div className="cources">
                <img className="h-24 md:h-36" src={wordpress} alt="" />
              </div>
              <h1 className="text-base md:text-2xl py-3">Wordpress</h1>
            </div>
            <div className="text-center flex flex-col justify-center items-center">
              <div className="cources">
                <img className="h-24 md:h-36" src={clang} alt="" />
              </div>
              <h1 className="text-base md:text-2xl py-3">C language</h1>
            </div>
            <div className="text-center flex flex-col justify-center items-center">
              <div className="cources">
                <img className="h-24 md:h-36" src={cplus} alt="" />
              </div>
              <h1 className="text-base md:text-2xl py-3">C++</h1>
            </div>
            <div className="text-center flex flex-col justify-center items-center">
              <div className="cources">
                <img className="h-24 md:h-36" src={flutter} alt="" />
              </div>
              <h1 className="text-base md:text-2xl py-3">Flutter</h1>
            </div>
            <div className="text-center flex flex-col justify-center items-center">
              <div className="cources">
                <img className="h-24 md:h-36" src={react} alt="" />
              </div>
              <h1 className="text-base md:text-2xl py-3">React Native</h1>
            </div>
          </div>
        </div>

        <div className="py-10">
          <div className="text-xl sm:text-3xl md:text-4xl text-center text-head py-2 sm:py-5">
            <h1>Frontand Development</h1>
          </div>
          <div className="grid grid-cols-2 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8 pt-14">
            <div className="text-center flex flex-col justify-center items-center">
              <div className="cources">
                <img className="h-24 md:h-36" src={html} alt="" />
              </div>
              <h1 className="text-base md:text-2xl py-3">HTML</h1>
            </div>
            <div className="text-center flex flex-col justify-center items-center">
              <div className="cources">
                <img className="h-24 md:h-36" src={css} alt="" />
              </div>
              <h1 className="text-base md:text-2xl py-3">CSS</h1>
            </div>
            <div className="text-center flex flex-col justify-center items-center">
              <div className="cources">
                <img className="h-24 md:h-36" src={javascript} alt="" />
              </div>
              <h1 className="text-base md:text-2xl py-3">JAVASCRIPT</h1>
            </div>
          </div>
        </div>

        <div className="py-10">
          <div className="text-xl sm:text-3xl md:text-4xl text-center text-head py-2 sm:py-5">
            <h1>Framework language</h1>
          </div>

          <div className="flex justify-center gap-5 md:gap-40 pt-14">
            <div className="text-center flex flex-col justify-center items-center">
              <div className="cources">
                <img className="h-24 md:h-36" src={boot} alt="" />
              </div>
              <h1 className="text-base md:text-2xl py-3">Bootstrap</h1>
            </div>
            <div className="text-center flex flex-col justify-center items-center">
              <div className="cources">
                <img className="h-24 md:h-36" src={tailwind} alt="" />
              </div>
              <h1 className="text-base md:text-2xl py-3">Tailwind</h1>
            </div>
          </div>
        </div>

        <div className="py-10">
          <div className="text-xl sm:text-3xl md:text-4xl text-center text-head py-2 sm:py-5">
            <h1>Graphics Design</h1>
          </div>
          <div className="grid grid-cols-2 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8 pt-14">
            <div className="text-center flex flex-col justify-center items-center">
              <div className="cources">
                <img className="h-24 md:h-36" src={photoshop} alt="" />
              </div>
              <h1 className="text-base md:text-2xl py-3">Photoshop</h1>
            </div>
            <div className="text-center flex flex-col justify-center items-center">
              <div className="cources">
                <img className="h-24 md:h-36" src={illustrator} alt="" />
              </div>
              <h1 className="text-base md:text-2xl py-3">illustrator</h1>
            </div>
            <div className="text-center flex flex-col justify-center items-center">
              <div className="cources">
                <img className="h-24 md:h-36" src={coral} alt="" />
              </div>
              <h1 className="text-base md:text-2xl py-3">Coral Draw</h1>
            </div>
            <div className="text-center flex flex-col justify-center items-center">
              <div className="cources">
                <img className="h-24 md:h-36" src={figma} alt="" />
              </div>
              <h1 className="text-base md:text-2xl py-3">Figma</h1>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
}

export default Institute;
