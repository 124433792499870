import React, { useState } from "react";
import { Dialog, Popover } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../assets/Images/Logo/Horizantal_Logo.svg";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <>
      <header className="bg-black ">
        <nav
          className="mx-auto flex container items-center justify-between py-2 px-4"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <NavLink>
              <span className="sr-only">Your Company</span>
              <img className="h-16 w-auto" src={logo} alt="" />
            </NavLink>
          </div>
          <div className="flex lg:hidden">
            {!mobileMenuOpen ? (
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            ) : (
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close main menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            )}
          </div>
          <Popover.Group className="hidden lg:flex lg:items-center lg:gap-x-12">
            <NavLink
              to="/"
              className={classNames(
                "text-base font-semibold leading-6 text-white",
                isActive("/") && "text_color"
              )}
            >
              Home
            </NavLink>

            <NavLink
              to="/about-us"
              className={classNames(
                "text-base font-semibold leading-6 text-white",
                isActive("/about-us") && "text_color"
              )}
            >
              About Us
            </NavLink>

            <NavLink
              to="/service"
              className={classNames(
                "text-base font-semibold leading-6 text-white",
                isActive("/service") && "text_color"
              )}
            >
              Services
            </NavLink>

            <NavLink
              to="/institute"
              className={classNames(
                "text-base font-semibold leading-6 text-white",
                isActive("/institute") && "text_color"
              )}
            >
              Institute
            </NavLink>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              <NavLink
                to="/contact-us"
                className={classNames(
                  "text-base font-semibold leading-6 text-black px-4 py-2 bg-white",
                  isActive("/contact-us") && "text-white bg_btn"
                )}
              >
                Contact Us <span aria-hidden="true">&rarr;</span>
              </NavLink>
            </div>
          </Popover.Group>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-black px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <NavLink href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img className="h-10 w-auto" src={logo} alt="" />
              </NavLink>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-white"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <NavLink
                    to="/"
                    className={classNames(
                      "-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white",
                      isActive("/") && "text_color"
                    )}
                  >
                    Home
                  </NavLink>
                  <NavLink
                    to="/about-us"
                    className={classNames(
                      "-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white",
                      isActive("/about-us") && "text_color"
                    )}
                  >
                    About Us
                  </NavLink>
                  <NavLink
                    to="/service"
                    className={classNames(
                      "-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white",
                      isActive("/service") && "text_color"
                    )}
                  >
                    Services
                  </NavLink>
                  <NavLink
                    to="/institute"
                    className={classNames(
                      "-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white",
                      isActive("/institute") && "text_color"
                    )}
                  >
                    Institute
                  </NavLink>
                  <NavLink
                    to="/contact-us"
                    className={classNames(
                      "-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white",
                      isActive("/contact-us") && "text_color"
                    )}
                  >
                    Contact Us
                  </NavLink>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    </>
  );
}

export default Header;
