import React from "react";

function Clientdetail() {
  return (
    <>
      <div className="py-10">
        <div className="text-head text-2xl md:text-3xl lg:text-5xl container mx-auto">
          <h1 className="sm:mx-10 md:mx-10 lg:mx-10">
            Embark on Success With Octant's IT Solutions.
          </h1>
        </div>

        <div className="container mx-auto py-8 md:py-10 lg:py-10 xl:py-20">
          <div className="max-w-md sm:max-w-full mx-auto sm:mx-5">
            <div className="bg_client mx-5 text-center py-5 gap-x-6 h-auto grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:gap-x-28">
              <div className="py-10 flex flex-col gap-2">
                <div className="text-3xl sm:text-3xl md:text-3xl xl:text-4xl text-white font-bold">
                  <h1>50 +</h1>
                </div>
                <div className="text-xl md:text-xl lg:text-2xl text_color_p font-semibold">
                  <h1>Connectors</h1>
                </div>
              </div>

              <div className="py-10 flex flex-col gap-2">
                <div className="text-3xl sm:text-3xl md:text-3xl xl:text-4xl text-white font-bold">
                  <h1>20 +</h1>
                </div>
                <div className="text-xl md:text-xl lg:text-2xl text_color_p font-semibold">
                  <h1>Employee</h1>
                </div>
              </div>

              <div className="py-10 flex flex-col gap-2">
                <div className="text-3xl sm:text-3xl md:text-3xl xl:text-4xl text-white font-bold">
                  <h1>35 +</h1>
                </div>
                <div className="text-xl md:text-xl lg:text-2xl text_color_p font-semibold">
                  <h1>Projects</h1>
                </div>
              </div>

              <div className="py-10 flex flex-col gap-2">
                <div className="text-3xl sm:text-3xl md:text-3xl xl:text-4xl text-white font-bold">
                  <h1>04</h1>
                </div>
                <div className="text-xl md:text-xl lg:text-2xl text_color_p font-semibold">
                  <h1>Awards</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Clientdetail;
