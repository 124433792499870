import React, { useEffect } from "react";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import banners from "../assets/Images/Banner/binary_banner.png";

import { CiMail } from "react-icons/ci";
import { IoIosCall } from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";

function Contact() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <div>
        <Header />
      </div>
      <div>
        <div className="text-center text-3xl relative">
          <div className="banner-overlay"></div>
          <img className="h-56 md:h-52 w-screen" src={banners} alt="" />
          <div className="absolute inset-0 py-16 md:py-10 lg:py-10 xl:py-14">
            <p className="tracking-widest text-xl md:text-4xl py-5 text-white">
              CONTACT US
            </p>
          </div>
        </div>

        <div className="container mx-auto py-12">
          <div className="text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-head py-3">
              Get in Touch
            </h1>
            <p className="text-base md:text-xl text_color_p">
              Tomorrow's Tech Talent Starts Here. Let's Talk.
            </p>
          </div>
          <div className="flex md:mx-4 flex-col gap-10 lg:flex-row items-center justify-around py-20">
            <div className="contact_box flex flex-col gap-2 justify-center items-center">
              <CiMail className="text-6xl contact_icon" />
              <h1 className="text-2xl font-bold">Email</h1>
              <p
                className="text-xl font-semibold cursor-pointer text_color_p"
                onClick={() =>
                  (window.location.href = "mailto:octantinfotech12@gmail.com")
                }
              >
                octantinfotech12@gmail.com
              </p>
            </div>
            <div className="contact_box flex flex-col gap-2 justify-center items-center">
              <IoIosCall className="text-6xl contact_icon" />
              <h1 className="text-2xl font-bold">Contact Number</h1>
              <p className="text-xl font-semibold cursor-pointer text_color_p">
                +91 6352045341
              </p>
            </div>
            <div className="contact_box flex flex-col gap-2 justify-center items-center">
              <IoLocationSharp className="text-6xl contact_icon" />
              <h1 className="text-2xl font-bold">Address</h1>
              <p className="text-xl font-semibold text-center text_color_p">
                814,Sliver Trade Center,Uttran, <br />
                Surat Gujarat 395010
              </p>
            </div>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3718.9701893460265!2d72.86729847598072!3d21.2330306807299!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f7adffdd9f9%3A0x8e5947b84d098fd9!2sSilver%20Trade%20Center!5e0!3m2!1sen!2sin!4v1705125556369!5m2!1sen!2sin"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
}

export default Contact;
